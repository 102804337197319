import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
// import principal_pic from '../../images/school_principal.jpg';
import "./PrincipalsDesk.css";

Modal.setAppElement('#root'); // Set the app element to prevent accessibility issues

const Notice = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [notice, setNotice] = useState([]);

  useEffect(() => {
    let domain = window.location.hostname;
    if (domain === 'localhost') {
      domain = 'demoschoolmanagement.in';
    }
    // const domain = process.env.REACT_APP_API_DOMAIN || 'demoschoolmanagement.in';
    axios
      .get(`https://api.${domain}/notice`) // Use backticks for template literals
      .then((response) => {
        // console.log(response.data);
        setNotice(response.data.content);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  // console.log(parentFeedback);
  

  useEffect(() => {
    let scrollInterval;
    if (!isHovered) {
      scrollInterval = setInterval(() => {
        const board = document.getElementById('noticeBoard');
        if (board.scrollTop < board.scrollHeight - board.clientHeight) {
          board.scrollTop += 1;
        } else {
          board.scrollTop = 0; // Restart the scroll
        }
      }, 50); // Adjust speed as needed
    }

    return () => {
      clearInterval(scrollInterval);
    };
  }, [isHovered]);

  const openModal = (selectedNotice) => {
    setSelectedNotice(selectedNotice);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };



  return (
    <div className="container my-4">
      <div
          id="noticeBoard"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div>
            {notice.map((ele, index) => (
              <p className='bg-success p-2 my-3'
                key={index}
                onClick={() => openModal(ele)}
              >
                <span><span className="badge badge-pill badge-warning mx-3">Notice {index + 1}</span></span>{ele.notice_title}
              </p>
            ))}
          </div>
        </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Notice Details"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        {selectedNotice && (
          <div>
            <h2>Notice Details</h2>
            <p>{selectedNotice.notice_details}</p>
            <button onClick={closeModal} style={{ marginTop: '20px' }}>Close</button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Notice;

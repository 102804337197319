import React, { useEffect, useState } from 'react';
import './HomePagePart5.css';

// Define the domain outside the component
const domain =
  window.location.hostname === 'localhost'
    ? 'demoschoolmanagement.in'
    : window.location.hostname;

const HomePagePart5 = () => {
  const [schoolPrograms, setSchoolPrograms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from the API
    const fetchPrograms = async () => {
      try {
        const response = await fetch(
          `https://dataserver.${domain}/website/get_conf.php?key=school_programs`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setSchoolPrograms(data.school_programs || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPrograms();
  }, []); // Empty dependency array as 'domain' is static

  if (loading) {
    return <div className="loading">Loading programs...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div className="homepage-part5">
      <div className="programs-title">Our Pre-school Programs</div>
      <p className="programs-intro">
        With age-appropriate stimulation, and the right balance of learning and fun, we encourage their curiosity and fuel their creative side.
      </p>
      <div className="programsContainer">
        {schoolPrograms.map((program, index) => (
          <div className="program-box" key={index}>
            <img
              alt={program.program_name}
              src={program.program_image}
              className="programImage"
            />
            <div className="program-name">{program.program_name}</div>
            <p className="program-age">{program.program_age}</p>
            <div className="program-description">{program.program_details}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePagePart5;

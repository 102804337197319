import React from 'react';
import { MdEmail } from "react-icons/md";
import { MdCallEnd } from "react-icons/md";
import './Topnavbar.css';

const TopNavBar = ({contact_info}) => {
  // console.log(information);
  return (
    <div className="topNavbar">
      <div className="topNavbar-info">
        <span className="topNavbar-number"><MdCallEnd /> {contact_info.phone}</span>
        <span className="topNavbar-email"><MdEmail /> {contact_info.email}</span>
      </div>
    </div>
  );
};

export default TopNavBar;

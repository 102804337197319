import React from 'react';
import Admin from '../PrincipalsDesk/Admin.js';
import Notice from '../PrincipalsDesk/Notice.js';

const AdminAndNotice = () => {
  return (
    <section className="container my-5">
      <div className="row">
        <h2 className="mb-3 text-center"><b>Admin And Notice</b></h2>

        {/* Column 1: Admin Section */}
        <div className="col-12 col-md-6 p-3 mb-3 mb-md-0">
         
          <Admin/>
        </div>

        {/* Column 2: Notice Section */}
        <div className="col-12 col-md-6 p-3">
          <Notice />
        </div>
      </div>
    </section>
  );
};

export default AdminAndNotice;

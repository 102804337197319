import React from 'react';
import './TermsCondition.css';

const TermsCondition = ({domain,school_name,email}) => {
  let website_url=`https://${domain}/`;
  return (
    <div className="terms-and-conditions-container">
                  <div className='container'>
      <h2 className="terms-header">Terms & Conditions</h2>
      <p className="terms-welcome-title">Welcome to {school_name}.</p>
      <p className="terms-introduction">These terms of service outline the rules and regulations for the use of {school_name}’s Website.</p>
      {/* <p className="terms-location">{school_name} is located at: <strong>Purba Medinipur, Chandipur 721633 – West Bengal, India</strong></p> */}

      <p className="terms-acceptance">By accessing this website we assume you accept these terms of service in full. Do not continue to use {school_name}’s website if you do not accept all of the terms of service stated on this page.</p>

      <h3 className="terms-terminology-header">Terminology</h3>
      <p className="terms-terminology-definition">
        The following terminology applies to these Terms of Service, Privacy Statement, and Disclaimer Notice and any or all Agreements:
        “Client”, “You” and “Your” refers to you, the person accessing this website and accepting the Company’s terms of service.
        “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client or ourselves.
        All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner,
        whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services/products,
        in accordance with and subject to, prevailing law of India. Any use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or they,
        are taken as interchangeable and therefore as referring to the same.
      </p>

      <h3 className="terms-cookies-header">Cookies</h3>
      <p className="terms-cookies-definition">
        We employ the use of cookies. By using {school_name}’s website you consent to the use of cookies in accordance with {school_name}’s privacy policy.
      </p>
      <p className="terms-cookies-description">
        Most modern interactive websites use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our site to enable the functionality of this area and ease of use for those visiting. Some of our affiliate/advertising partners may also use cookies.
      </p>

      <h3 className="terms-license-header">License</h3>
      <p className="terms-license-description">
        Unless otherwise stated, {school_name} and/or its licensors own the intellectual property rights for all material on {school_name}. All intellectual property rights are reserved.
        You may view and/or print pages from <a href={website_url} target="_blank" rel="noopener noreferrer">{domain}</a> for your own personal use subject to restrictions set in these terms of service.
      </p>

      <p className="terms-license-restrictions"><strong>You must not:</strong></p>
      <ul className="terms-restriction-list">
        <li>Republish material from <a href={website_url} target="_blank" rel="noopener noreferrer">{domain}</a></li>
        <li>Sell, rent, or sub-license material from <a href={website_url} target="_blank" rel="noopener noreferrer">{domain}</a></li>
        <li>Reproduce, duplicate or copy material from <a href={website_url} target="_blank" rel="noopener noreferrer">{domain}</a></li>
      </ul>
      <p className="terms-license-description">Redistribute content from {school_name} (unless content is specifically made for redistribution).</p>

      <h3 className="terms-hyperlinking-header">Hyperlinking to our Content</h3>
      <p className="terms-hyperlinking-description">
        The following organizations may link to our website without prior written approval:
      </p>
      <ul className="terms-allowed-hyperlinking-list">
        <li>Government agencies;</li>
        <li>Search engines;</li>
        <li>News organizations;</li>
        <li>Online directory distributors;</li>
        <li>Systemwide Accredited Businesses.</li>
      </ul>
      <p className="terms-hyperlinking-description">We will approve link requests from these organizations if we determine that: (a) the link would not reflect unfavorably on us or our accredited businesses; (b) the organization does not have an unsatisfactory record with us; (c) the benefit to us from the visibility associated with the hyperlink outweighs the absence of {school_name}; and (d) where the link is in the context of general resource information or is otherwise consistent with editorial content in a newsletter or similar product furthering the mission of the organization.</p>

      <p className="terms-hyperlinking-description">These organizations may link to our home page, publications, or other website information so long as the link:
        (a) is not misleading; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.
      </p>

      <p className="terms-hyperlinking-description">If you are among the organizations listed above and are interested in linking to our website, you must notify us by sending an e-mail to {email}.
        Please include your name, your organization name, contact information (such as a phone number and/or e-mail address) as well as the URL of your site, a list of any URLs from which you intend to link to our website, and a list of the URL(s) on our site to which you would like to link. Allow 2-3 weeks for a response.
      </p>

      <p className="terms-hyperlinking-description">Approved organizations may hyperlink to our website as follows:</p>

      <ul className="terms-hyperlinking-conditions-list">
        <li>By use of our corporate name; or</li>
        <li>By use of the uniform resource locator (Web address) being linked to; or</li>
        <li>By use of any other description of our website or material being linked to that makes sense within the context and format of content on the linking party’s site.</li>
      </ul>

      <p>No use of {school_name}’s logo or other artwork will be allowed for linking absent a trademark license agreement.</p>

      <h3 className="terms-iframes-header">Iframes</h3>
      <p className="terms-iframes-definition">
        Without prior approval and express written permission, you may not create frames around our web pages or use other techniques that alter in any way the visual presentation or appearance of our website.
      </p>

      <h3 className="terms-content-liability-header">Content Liability</h3>
      <p className="terms-content-liability-definition">
        We shall have no responsibility or liability for any content appearing on your website. You agree to indemnify and defend us against all claims arising out of or based upon your website.
        No link(s) may appear on any page on your website or within any context containing content or materials that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
      </p>

      <h3 className="terms-reservation-rights-header">Reservation of Rights</h3>
      <p className="terms-reservation-rights-definition">
        We reserve the right at any time and in its sole discretion to request that you remove all links or any particular link to our website. You agree to immediately remove all links to our website upon such request.
        We also reserve the right to amend these terms of service and its linking policy at any time. By continuing to link to our website, you agree to be bound to and abide by these linking terms of service.
      </p>

      <h3 className="terms-removal-links-header">Removal of Links from Our Website</h3>
      <p className="terms-removal-links-description">
        If you find any link on our website or any linked website objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you.
      </p>

      <p className="terms-removal-links-description">Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is kept up to date.</p>

      <h3 className="terms-disclaimer-header">Disclaimer</h3>
      <p className="terms-disclaimer-introduction">
        To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website
        (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill).
        Nothing in this disclaimer will:
      </p>
      <ul className="terms-disclaimer-conditions-list">
        <li>Limit or exclude our or your liability for death or personal injury resulting from negligence;</li>
        <li>Limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
        <li>Limit any of our or your liabilities in any way that is not permitted under applicable law;</li>
        <li>Exclude any of our or your liabilities that may not be excluded under applicable law.</li>
      </ul>

      <p className="terms-disclaimer-conclusion">
        The limitations and exclusions of liability set out in this section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty.
      </p>

      <p className="terms-free-service-notice">
        To the extent that the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
      </p>
      </div>
    </div>
  );
};

export default TermsCondition;

import React, { useEffect, useState } from 'react';

const InfoSection = ({ logo, domain }) => {
  const [socialMediaLinks, setSocialMediaLinks] = useState({});

  useEffect(() => {
    // Fetch social media links from the API
    const fetchSocialMediaLinks = async () => {
      try {
        const response = await fetch(
          `https://dataserver.${domain}/website/get_conf.php?key=social_media`
        );
        const data = await response.json();
        setSocialMediaLinks(data.social_media);
      } catch (error) {
        console.error('Error fetching social media links:', error);
      }
    };
  
    fetchSocialMediaLinks();
  }, [domain]); // Add 'domain' as a dependency
  const admin_login_url = `https://admin.${domain}/`;
  const teacher_login_url = `https://teacher.${domain}/`;
  const student_login_url = `https://student.${domain}/`;

  return (
    <section className="info_section layout_padding">
      <div className="container">
        <div className="info_form"></div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="info_logo">
              <div>
                <a href="/">
                  <img
                    src={logo}
                    alt="Pluto Logo"
                    style={{ height: '120px' }}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="info_links d-flex flex-column">
              <h5>Important Links</h5>
              <a href={admin_login_url} className="pr-0 pr-md-4 pr-lg-5">
                Admin Login
              </a>
              <a href={teacher_login_url} className="pr-0 pr-md-4 pr-lg-5">
                Teacher Login
              </a>
              <a href={student_login_url} className="pr-0 pr-md-4 pr-lg-5">
                Student Login
              </a>
            </div>
          </div>
          <div className="col-md-3">
            <div className="info_insta d-flex flex-column">
              <h5>Pages</h5>
              <a href="/termscondition" className="pr-0 pr-md-4 pr-lg-5">
                Terms & Condition
              </a>
              <a href="/privacypolicy" className="pr-0 pr-md-4 pr-lg-5">
                Privacy Policy
              </a>
              <a href="/disclaimer" className="pr-0 pr-md-4 pr-lg-5">
                Disclaimer
              </a>
            </div>
          </div>
          <div className="col-md-3">
            <div className="pl-0 pl-lg-5 pl-md-4">
              <h5>Social Media</h5>
              {Object.entries(socialMediaLinks).map(([platform, link]) => (
                <li style={{ textDecoration: "none" ,listStyle: "none"}}><a
                key={platform}
                href={link}
                className="pr-0 pr-md-4 pr-lg-5"
                target="_blank"
                rel="noopener noreferrer"
              >
                {platform.charAt(0).toUpperCase() + platform.slice(1)}
              </a></li>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoSection;

import React, { useEffect, useState } from 'react';
import './Admin.css'; // Optional CSS for styling

const AdminList = () => {
  const [admins, setAdmins] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let domain = window.location.hostname;
    if (domain === 'localhost') {
      domain = 'demoschoolmanagement.in';
    }
    const fetchAdmins = async () => {
      try {
        const response = await fetch(
          `https://dataserver.${domain}/website/get_conf.php?key=school_admins`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setAdmins(data.school_admins);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAdmins();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="admin-list-container">

      <div className="admin-cards">
        {admins.map((admin, index) => (
          <div key={index} className="admin-card">
            <div className="admin-card-inner">
              <img
                src={admin.profile_photo || 'https://dataserver.demoschoolmanagement.in/website/unknown.png'}
                alt={admin.name}
                className="admin-photo"
              />
              <div className="admin-details">
                <h5>{admin.name}</h5>
                <p>{admin.position}</p>

              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminList;
import React from 'react';
import "./Programe.css";
import HomePagePart5 from '../../components/HomePagePart5/HomePagePart5';

const Programe = () => {
    return (
        <div className='program-overview'>
            <div className="program-intro">
                <div className="program-header">
                    <h1 className="program-title">Programs</h1>
                    <p className="program-tagline">Hop on for a fun learning experience.</p>
                </div>
            </div>
            
               <HomePagePart5/>
            </div>
        
    )
}

export default Programe;

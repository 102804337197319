import React from 'react';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Topnavbar from './components/TopNavBar/Topnavbar';
import Header from './components/Header/Header';
import Info from './components/template/Newsletter';
import Footer from './components/template/Footer';
import AboutUs from './pages/About Us/AboutUs';
import ContactUs from './pages/ContactUs/ContactUs';
import Programe from './pages/Programe/Programe';
import Home from './pages/HomePage/Home';
import Gallery from './pages/Gallery/Gallery';
import './App.css';
import Disclaimer from './pages/Disclaimer/Disclaimer';
import TermsCondition from './pages/Term_Condition/TermsCondition';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';

function App() {
  const [contact_info, setContactInformation] = useState("");
  const [logo, setLogo] = useState("");
  const [email, setEmail] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [data, setData] = useState(null);
  const [contactForm, setcontactForm] = useState([]);
  console.log(data);

  // Set the base URL dynamically using environment variables
  let domain = window.location.hostname;

  if (domain === 'localhost') {
    domain = 'demoschoolmanagement.in';
  }


  // Fetch data when the component mounts
  useEffect(() => {
    axios
      .get(`https://dataserver.${domain}/website/get_conf.php`)
      .then((response) => {
        console.log(response.data);
        setData(response.data); 
        setContactInformation(response.data.contact_section);
        setLogo(response.data.logo);
        setSchoolName(response.data.school_name);       
        setcontactForm(response.data.contact_section);  
        setEmail(response.data.contact_info.email);
      })
      .catch((err) => {
        console.log(err.message);       
      });
  }, [domain]);

  return (
    <Router>
      <Topnavbar contact_info={contact_info} />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs contactForm={contactForm}/>} />
        <Route path="/programe" element={<Programe />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/disclaimer" element={<Disclaimer domain={domain} school_name={schoolName} email={email}/>} />
        <Route path="/termscondition" element={<TermsCondition domain={domain} school_name={schoolName} email={email}/>} />
        <Route path="/privacypolicy" element={<PrivacyPolicy domain={domain} school_name={schoolName} email={email}/>} />
      </Routes>
      <Info logo={logo} domain={domain}/>
      <Footer />
    </Router>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import "./HomePagePart6.css";

const testimonialsData = [
  {
    image: "https://www.toddlertown.co.in/wp-content/uploads/2023/09/Copy-of-H-2-1.jpg",
    name: "B. Mallick",
    description: "Best nursery for kids as the place is clean, hygienic, kids are safe and secure, and they can have fun and frolic while learning."
  },
  {
    image: "https://www.toddlertown.co.in/wp-content/uploads/2023/09/241401202_1661645567379057_2687064486190071875_n.jpg",
    name: "P. Chowdhury",
    description: "Toddler Town has given me and my son the best memories. The teachers are well trained, and I fell in love with the curriculum design."
  },
  {
    image: "https://www.toddlertown.co.in/wp-content/uploads/2023/09/314409328_808545787116031_5236725963032947028_n.jpg",
    name: "K. K. Ghosh",
    description: "A school that excels in both the academic curriculum and infrastructure to cater to the needs of tiny tots and shape them."
  },
  {
    image: "https://www.toddlertown.co.in/wp-content/uploads/2023/09/Copy-of-H-2-1.jpg",
    name: "B. Mallick 2",
    description: "Best nursery for kids as the place is clean, hygienic, kids are safe and secure, and they can have fun and frolic while learning."
  },
  {
    image: "https://www.toddlertown.co.in/wp-content/uploads/2023/09/241401202_1661645567379057_2687064486190071875_n.jpg",
    name: "P. Chowdhury 2",
    description: "Toddler Town has given me and my son the best memories. The teachers are well trained, and I fell in love with the curriculum design."
  },
  {
    image: "https://www.toddlertown.co.in/wp-content/uploads/2023/09/314409328_808545787116031_5236725963032947028_n.jpg",
    name: "K. K. Ghosh 2",
    description: "A school that excels in both the academic curriculum and infrastructure to cater to the needs of tiny tots and shape them."
  },
];

const HomePagePart6 = ({teacherImage}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // console.log(teacherImage);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % (testimonialsData.length)); // Loop through the testimonials
    }, 3000); // Change testimonial every 3 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <div className="container mb-5">
<div className="homepage-container">
      <div className="programs-title mb-3">Our Teachers</div>
      <div className="testimonial-container">
        <div className="testimonial-wrapper" style={{ transform: `translateX(-${currentIndex * 200}px)` }}>
          {teacherImage.map((teacher, index) => (
            <div className="testimonial" key={index}>
              <img className="testimonial-image" src={teacher.teacher_photo || 'https://dataserver.demoschoolmanagement.in/website/unknown.png'} alt={teacher.teacher_photo} />
              <div className="testimonial-content">
                <h3 className="testimonial-name">{teacher.teacher_name}</h3>
                {/* <p className="testimonial-description">{testimonial.description}</p> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
    
  );
}

export default HomePagePart6;

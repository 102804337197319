import React from 'react';
import './HomePagePart3.css'; // Ensure this CSS file is created

const HomePagePart3 = ({ourMoto}) => {
  return (
    <div className="homepage-part3">
      <div className="feature-container">
        <div className="feature-item">
          <img
            alt="Vision"
            src="https://dataserver.demoschoolmanagement.in/website/icons/vision.webp"
            loading="lazy"
            className="feature-image"
          />
          <div className='feature-box'>
            <div className="feature-title">Vision</div>
            <div className="feature-description">
             {ourMoto.Vision}
            </div>
          </div>
        </div>
        <div className="feature-item">
          <img
            alt="Mission"
            src="https://dataserver.demoschoolmanagement.in/website/icons/mission.webp"
            loading="lazy"
            className="feature-image"
          />
          <div className='feature-box'>
            <div className="feature-title">Mission</div>
            <div className="feature-description">
            {ourMoto.Mission}
            </div>
          </div>
        </div>
        <div className="feature-item">
          <img
            alt="Philosophy"
            src="https://dataserver.demoschoolmanagement.in/website/icons/philosophy.webp"
            loading="lazy"
            className="feature-image"
          />
          <div className='feature-box'>
            <div className="feature-title">Philosophy</div>
            <div className="feature-description">
            {ourMoto.Philosophy}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePagePart3;

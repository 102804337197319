import React, { useState } from "react";

const ContactSection = ({ contactForm }) => {
  const contact_form_email = contactForm.contact_form_email;
  console.log("Contact Form Email => ", contact_form_email);

  const googleMapEmbedUrl = contactForm.google_embedded_map;

  // State to manage form inputs
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  let domain = window.location.hostname;

  if (domain === 'localhost') {
    domain = 'demoschoolmanagement.in';
  }


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`https://api.${domain}/getintouch`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Form submission successful:", data);
        alert("Your message has been sent successfully!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        console.error("Error submitting form:", response.statusText);
        alert("Failed to send your message. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <section className="contact_section layout_padding">
      <div className="container">
        <div className="heading_container">
          <h2>
            Request
            <span> A call Back</span>
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <form onSubmit={handleSubmit}>
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  name="message"
                  className="message-box"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="d-flex mt-4">
                <button type="submit">SEND</button>
              </div>
            </form>
          </div>
          <div className="col-md-8">
            {/* Map section */}
            <div className="map_section">
              <div
                dangerouslySetInnerHTML={{ __html: googleMapEmbedUrl }}
                className="map_iframe"
              />
            </div>
            {/* End map section */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;

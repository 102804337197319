// About.js
import React from 'react';
import './AboutUs.css'; // Import CSS for styling



const AboutUs = () => {
  return (
    <div className="about-section">
      <div className="about-content">
        <div className="about-header">
          <h1>ABOUT US</h1>
          <p>Your precious are in safe hands.</p>
        </div>
      </div>


      <div className="school-section container">
        <div className="school-description">
          <div className="school-title">Our School</div>
          <p className='school-descption'>
          Welcome to our school – a place where education inspires excellence, shaping young minds to become confident, compassionate, and capable individuals.

We are dedicated to providing a nurturing environment that encourages creativity, critical thinking, and holistic development. Established with a mission to foster lifelong learning, our school combines academic rigor with opportunities for personal growth, ensuring every student reaches their fullest potential.
          </p>
        </div>
        <div className="school-image">
          <img src="https://heraldkids.com/images/our-mission.jpg" alt="Our Mission" className='misson-image' />
        </div>
      </div>
    </div>

  );
};

export default AboutUs;

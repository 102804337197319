import React from 'react';
import "./Gallery.css";

const Gallery = () => {
    const images = [
        " https://heraldkids.com/backend/gallery/images/2021-03-04-13-56-07-rhymes_day_03.jpg",
        " https://heraldkids.com/backend/gallery/images/2021-03-04-13-49-51-rabi1.jpg",
        " https://heraldkids.com/backend/gallery/images/2021-03-04-13-56-07-rhymes_day_03.jpg",
        " https://heraldkids.com/backend/gallery/images/2021-03-04-13-48-38-swim1.jpg",
        " https://heraldkids.com/backend/gallery/images/2021-03-04-13-47-30-art_and_craft_06.jpg",
        " https://heraldkids.com/backend/gallery/images/2021-03-04-13-55-02-annual_function_07.jpg",
        " https://heraldkids.com/backend/gallery/images/2021-03-04-13-56-07-rhymes_day_03.jpg",
    ];

    return (
        <div className='gallery-section'>
            <div className="gallery-content">
                <div className="gallery-header">
                    <h1>Gallery</h1>
                    <p>Sneak peek of the fun time!</p>
                </div>
            </div>
 
            <div className="image-gallery container">
                {images.map((image, index) => (
                    <div className="gallery-item" key={index}>
                        <img src={image} alt={`Gallery item ${index + 1}`} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Gallery;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from "react-helmet";

const FooterSection = () => {
  let domain = window.location.hostname;

  if(domain==='localhost'){
    domain='demoschoolmanagement.in';
  }

  const [schoolName, setSchoolName] = useState(""); 


  useEffect(() => {
    let domain = window.location.hostname;
  
    if (domain === 'localhost') {
      domain = 'demoschoolmanagement.in';
    }
    axios
      .get(`https://dataserver.${domain}/website/get_conf.php`)
      .then((response) => {
        setSchoolName(response.data.school_name);
      })
      .catch((err) => {
        console.log(err.message);       
      });
  }, []);


  const school_url = `https://${domain}/`; // Fixed string interpolation

  const currentYear = new Date().getFullYear(); // Get current year dynamically

  return (
    <section className="container-fluid footer_section">
       <Helmet>
        <title>{schoolName}</title>
      </Helmet>
      <p>
        <span className='px-3'>&copy; Copyright {currentYear} 
          <a className='text-warning px-2' href={school_url} target="_blank" rel="noopener noreferrer">
          {schoolName} 
          </a> All Rights Reserved
        </span>
      </p>
    </section>
  );
};

export default FooterSection;

import React from 'react';

const ClientFeedback = ({ parentFeedback }) => {
  // Return null if the feedback list is empty
  if (!parentFeedback || parentFeedback.length === 0) {
    return null;
  }

  return (
    <section className="client_section layout_padding">
      <div className="container layout_padding2-top">
        <div className="heading_container">
          <h2>Parent’s Feedback</h2>
        </div>
        <div id="carouselExampleControls" className="carousel slide">
          <div className="carousel-inner">
            {parentFeedback.map((feedback, index) => (
              <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <div className="client_container layout_padding">
                  <div className="detail-box">
                    <h4>{feedback.guardian_name}</h4>
                    <p>{feedback.review}</p>
                    
                  </div>
                </div>
              </div>
            ))}
          </div>
          <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ClientFeedback;

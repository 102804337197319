// CarouselComponent.js
import React, { useEffect, useRef } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./CarouselComponent.css";



const CarouselComponent = ({crousalImage}) => {
  const carouselRef = useRef(null);

  useEffect(() => {
    if (carouselRef.current) {
      const interval = setInterval(() => {
        if (carouselRef.current) {
          carouselRef.current.next();
        }
      }, 3000); // Slide every 3 seconds

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, []);

  return (
    <Carousel ref={carouselRef} controls={false} indicators={false} wrap={true} className='carousalComponent'>
      {crousalImage.map((image, index) => (
        <Carousel.Item key={index}>
          <img
            className="carousel-image"
            src={image.crowsel_image}
            alt={image.alt}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
